// loaded on all pages in dashboard and portal admin
var makersApp = {};

$(function() {

});

makersApp.copyToEmbedClipboard = function(text, url){
  // if ("ga" in window) {
  //   tracker = ga.getAll()[0];
  //   if (tracker) {
  //     tracker.send('event', 'design_share', 'copy_glb_to_embed_clipboard');
  //   }
  // }
  var dummy =  document.createElement('input');
  var out = '<script type="module" src="https://cdn.jsdelivr.net/npm/@google/model-viewer@1.9.2/dist/model-viewer-umd.min.js"></script>'
  out += '<model-viewer src="' + text + '" alt="3D model in Makers Empire" auto-rotate camera-controls></model-viewer>';

  var out='<script type="module" src="https://cdn.jsdelivr.net/npm/@google/model-viewer@1.9.2/dist/model-viewer-umd.min.js"></script>'
  out += '<div style="background-color:#fff; width:400px; height: 300px; font-family:arial;text-align:center">'
  out += '<model-viewer style="width:100%; height:100%" '
  out += 'src="' + text + '" alt="A 3D model of an astronaut" auto-rotate camera-controls></model-viewer>'
  out += '<a href="' + url + '">Made in Makers Empire. See design</a> </div>';
  
  document.body.appendChild(dummy);
  dummy.value = out;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

makersApp.copyToClipboard = function(text) {
  var dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

makersApp.lessonPlanEmail = function(input) {
  $('#mce-EMAIL').val($('#'+input).val());
}

makersApp.clearForm = function(){
  $("input, textarea").val("");
}

makersApp.validateEmail = function(email) {
  var re = /^.+@.+\..+$/;
  return re.test(email);
} 

makersApp.truncate = function(str, n){
  return (str.length > n) ? str.substr(0, n - 1) + '&hellip;' : str;
};

// make it globally accessible
window.makersApp = makersApp;
